import { makeApiCall } from '../../../src/utils/api/apiHelper.js'
import { ProdAndTestCred } from '../../../src/utils/api/main.js'

export default {
  actions: {
    async getCreditProducts(ctx) {
      const data = {
        method: 'get_credit_products'
      }

      await makeApiCall(data, ProdAndTestCred.tkRequests, ctx, 'updateProducts')
    }
  },
  mutations: {
    updateProducts(state, products) {
      state.products = products
    }
  },
  state: {
    products: []
  },
  getters: {
    creditProducts(state) {
      return state.products
    }
  }
}
