import { createApp, onMounted, h } from 'vue'
import store from './store'
import 'es6-promise/auto'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import router from './router'
import 'material-icons/iconfont/material-icons.css'
import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'
// migration imports

import appIcon from './assets/images/app-d.png'
import './utils/validation'
import mitt from 'mitt'
import VueScrollTo from 'vue-scrollto'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { IMaskDirective } from 'vue-imask'

const head = createHead()

export const eventBus = mitt()

const app = createApp({
  setup() {
    onMounted(() => {
      if (navigator.userAgent.indexOf('gonative') === -1) {
        new SmartBanner({
          daysHidden: 1,
          daysReminder: 1,
          title: 'Ocean.me',
          author: '',
          button: 'Відкрити',
          store: {
            ios: 'В App Store',
            android: 'В Google Play'
          },
          price: {
            ios: 'Безкоштовно',
            android: 'Безкоштовно'
          },
          icon: appIcon
        })
      }
    })
  },
  render() {
    return h(App)
  }
})

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    app,
    dsn: 'https://a46ffde07a2d43269749109a9d49ac6c@sentry.io/5185061',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
  })
}

app.use(store)
app.use(router)
app.use(head)
app.use(VueScrollTo)
app.use(ToastPlugin)
app.directive('imask', IMaskDirective)

app.directive('touppercase', {
  beforeMount(el) {
    el.addEventListener('input', () => {
      el.value = el.value.toUpperCase()
    })
  },
  unmounted(el) {
    el.removeEventListener('input', () => {
      el.value = el.value.toUpperCase()
    })
  }
})
app.use(BootstrapVue3)
app.use(VueScrollTo, {
  container: 'body',
  duration: 2000,
  easing: 'ease-in',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

app.config.productionTip = false

function typeConversion(value) {
  if (value !== null && value !== undefined) {
    return value.toString().split(',').join('')
  }
}

app.config.globalProperties.currencyFormat = function (value) {
  value = typeConversion(value)
  if (value !== null && value !== undefined) {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}

app.config.globalProperties.toNum = function (value) {
  value = typeConversion(value)
  if (value !== null) {
    return parseInt(value)
  }
}

app.mount('#app')
