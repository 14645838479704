import { makeApiCall } from '../../../src/utils/api/apiHelper.js'
import { ProdAndTestCred } from '../../../src/utils/api/main.js'

export default {
  actions: {
    async getSimplePersonalData(ctx) {
      const endpoint = `${ProdAndTestCred.simpleRegistration}${localStorage.idFastRegistration}/`
      const data = {
        PCAuthToken: localStorage.tokenFastRegistration
      }

      await makeApiCall(data, endpoint, ctx, 'updateSimpleDetails')
    }
  },
  mutations: {
    updateSimpleDetails(state, details) {
      state.details = details
    }
  },
  state: {
    details: []
  },
  getters: {
    simpleCustomerDetails(state) {
      return state.details
    }
  }
}
