import axios from 'axios'
import { getToken } from '../../../src/utils/api/getToken.js'

export const makeApiCall = async (data, endpoint, ctx, mutationType) => {
  try {
    let response = await axios.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access')
      }
    })
    ctx.commit(
      mutationType,
      mutationType === 'updateSimpleDetails'
        ? response.data.client
        : mutationType === 'updateCard'
        ? response.data
        : response.data.tk_response
    )
  } catch (err) {
    if (err.response.data.code === 'token_not_valid') {
      await getToken()
      await makeApiCall(data, endpoint, ctx, mutationType)
      return
    }
    console.log(err.response)
  }
}
