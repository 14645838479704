import { makeApiCall } from '../../../src/utils/api/apiHelper.js'
import { ProdAndTestCred } from '../../../src/utils/api/main.js'

export default {
  actions: {
    async getActiveLoans(ctx) {
      const data = {
        method: 'get_customer_loans',
        user_auth_token: localStorage.getItem('AuthToken')
      }

      await makeApiCall(data, ProdAndTestCred.tkRequests, ctx, 'updateLoans')
      await transformLoanStatuses(ctx.state.loans)
      await calculateLoanSchedules(ctx.state.loans)
    }
  },
  mutations: {
    updateLoans(state, loans) {
      state.loans = loans
    }
  },
  state: {
    loans: []
  },
  getters: {
    activeLoans(state) {
      return state.loans
    }
  }
}

const transformLoanStatuses = async loans => {
  const statusMap = {
    Approved: 'Прийнята',
    Active: 'Активна',
    WaitingForApproval: 'Очікує рішення',
    PastDue: 'Прострочена',
    Origination: 'На редагуванні',
    Reprocessing: 'Повторна обробка',
    AutoProcessing: 'Автоматична обробка',
    RolloverRequested: 'Запит на відстрочку',
    Collateral: 'Опрацювання застави',
    DisbursementFailed: 'Видача неуспішна',
    DisbursementInProgress: 'В процесі видачі',
    P2P_GatheringInvestments: 'Очікує інвестиції',
    WaitingForCustomerSignature: 'Підписання договору',
    BankAccountVerification: 'Очікує перевірки рахунку',
    PreOrigination: 'Незаповнена анкета'
  }

  loans.forEach(loan => {
    loan.Status = statusMap[loan.Status] || loan.Status
  })
}

const calculateLoanSchedules = async loans => {
  loans.forEach(loan => {
    loan.scheduelClosed = loan.Schedule.reduce((acc, status) => {
      return acc + (status.IsClosed || !status.Total ? 1 : 0)
    }, 0)
  })
}
