export const ProdAndTestCred = (function () {
  let mainSiteURL = ''
  let siteURL = ''
  let endPointClients = ''
  let endPointRating = ''
  let endPointFeedback = ''
  let backendToken = ''
  let turnkeyClients = ''
  let storeProducts = ''
  let endPointHash = ''
  let endPointServiceUrl = ''
  let shopping = ''
  let merchantRegister = ''
  let accountRequest = ''
  let catalog = ''
  let signForm = ''
  let shoppingCategories = ''
  let livnessDetection = ''
  let getMerchant = ''
  let tkRequests = ''
  let agentRegister = ''
  let tkRequestsUpload = ''
  let bankIdCheck = ''
  let bankIdRequest = ''
  let markerPlace = ''
  let catalogPopular = ''
  let catalogCategoryBrands = ''
  let appsVisitLog = ''
  let desktopmessage = ''
  let shoppingOrders = ''
  let clientId = ''
  let simpleRegistration = ''
  let loginSimpleRegistration = ''
  let signAgreement = ''
  let getSignedAgreement = ''
  let getWidgetMerchants = ''
  let phoneRegisterUpdate = ''
  let formCount = ''
  let widgetSession = ''
  let qrCodeMonkey = ''
  let sendSms = ''
  let ideaBankRequest = ''
  let posts = ''
  let services = ''
  let getToken = ''


  const domain = window.location.hostname
  if (['ocean.credit', 'ocean.me', 'ocean.ua'].includes(domain)) {
    mainSiteURL = 'https://ocean.me'
    siteURL = 'https://my.ocean.credit'
    endPointClients = 'https://ocean-credit.herokuapp.com/api/clients/'
    endPointRating = 'https://ocean-credit.herokuapp.com/api/websiterating/'
    endPointFeedback = 'https://ocean-credit.herokuapp.com/api/comments/'
    backendToken = 'Token 1f1329b026ddeea1d6c7dd58322894b3df4b37d4'
    turnkeyClients = 'https://ocean-credit.herokuapp.com/api/turnkeyclients/'
    endPointHash =
      'https://ocean-credit.herokuapp.com/oceanapi/wayforpay/merchantsignature/'
    endPointServiceUrl =
      'http://ocean-credit.herokuapp.com/oceanapi/wayforpay/verifyservice/'
    storeProducts =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/storeproducts/'
    shopping = 'https://ocean-credit.herokuapp.com/oceanapi/epos/shopping/'
    merchantRegister =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/merchant/'
    accountRequest = 'https://ocean-credit.herokuapp.com/api/accountrequest/'
    catalog = 'https://ocean-credit.herokuapp.com/oceanapi/epos/catalog/'
    signForm = 'https://ocean-credit.herokuapp.com/api/messagerequest/'
    shoppingCategories =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/categories/'
    livnessDetection = 'https://ocean-credit.herokuapp.com/api/livenesscheck/'
    getMerchant = 'https://ocean-credit.herokuapp.com/oceanapi/epos/agents/all/'
    tkRequests = 'https://ocean-credit.herokuapp.com/api/turnkeyrequest/'
    agentRegister = 'https://ocean-credit.herokuapp.com/oceanapi/epos/agents/'
    tkRequestsUpload =
      'https://ocean-credit.herokuapp.com/api/turnkeyrequest/uploadfiles/'
    bankIdRequest =
      'https://ocean-credit.herokuapp.com/oceanapi/bankid/client_request'
    bankIdCheck =
      'https://ocean-credit.herokuapp.com/oceanapi/bankid/client_check'
    markerPlace =
      'https://ocean-credit.herokuapp.com/api/affiliate/creditmarket/'
    catalogPopular =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/catalog/popular/'
    catalogCategoryBrands =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/allbrands/'
    desktopmessage = 'https://ocean-credit.herokuapp.com/api/desktopmessage/'
    shoppingOrders =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/shopping-orders/'
    clientId = 'https://ocean-credit.herokuapp.com/api/customerident/'
    simpleRegistration =
      'https://ocean-credit.herokuapp.com/api/simple-registration/clients/'
    loginSimpleRegistration =
      'https://ocean-credit.herokuapp.com/api/simple-registration/clients/auth/'
    signAgreement = 'https://ocean-credit.herokuapp.com/api/qes/contract/sign/'
    getSignedAgreement = 'https://ocean-credit.herokuapp.com/api/qes/contract/'
    getWidgetMerchants =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/merchants/'
    phoneRegisterUpdate =
      'https://ocean-credit.herokuapp.com/api/phone-register-update/'
    formCount = 'https://ocean-credit.herokuapp.com/api/clients/form-count/'
    widgetSession =
      'https://ocean-credit.herokuapp.com/oceanapi/epos/widgetsession/'
    qrCodeMonkey = 'https://ocean-credit.herokuapp.com/oceanapi/epos/qrcode/'
    sendSms = 'https://ocean-credit.herokuapp.com/api/widgetmessage/'
    ideaBankRequest =
      'https://ocean-credit.herokuapp.com/api/clients/get-ideabank-request/'
    posts = 'https://ocean-credit.herokuapp.com/api/blog/news/'
    services = 'https://ocean-credit.herokuapp.com/api/services/'
    getToken = 'https://ocean-credit.herokuapp.com/api/token/'
 

  } else {
    mainSiteURL = 'https://ocean-credit-stage.web.app'
    siteURL = 'https://ocean-uat.turnkey-lender.com'
    getToken = 'https://test-ocean-credit-app.herokuapp.com/api/token/'
    endPointClients = 'https://test-ocean-credit-app.herokuapp.com/api/clients/'
    endPointRating =
      'https://test-ocean-credit-app.herokuapp.com/api/websiterating/'
    endPointFeedback =
      'https://test-ocean-credit-app.herokuapp.com/api/comments/'
    backendToken = 'Token 0e0dafee7321cbfbdde21330691146ec06726260'
    turnkeyClients =
      'https://test-ocean-credit-app.herokuapp.com/api/turnkeyclients/'
    storeProducts =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/storeproducts/'
    endPointHash =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/wayforpay/merchantsignature/'
    endPointServiceUrl =
      'http://test-ocean-credit-app.herokuapp.com/oceanapi/wayforpay/verifyservice/'
    shopping =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/shopping/'
    merchantRegister =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/merchant/'
    accountRequest =
      'https://test-ocean-credit-app.herokuapp.com/api/accountrequest/'
    catalog =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/catalog/'
    signForm = 'https://test-ocean-credit-app.herokuapp.com/api/messagerequest/'
    shoppingCategories =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/categories/'
    livnessDetection =
      'https://test-ocean-credit-app.herokuapp.com/api/livenesscheck/'
    getMerchant =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/agents/all/'
    tkRequests =
      'https://test-ocean-credit-app.herokuapp.com/api/turnkeyrequest/'
    agentRegister =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/agents/'
    tkRequestsUpload =
      'https://test-ocean-credit-app.herokuapp.com/api/turnkeyrequest/uploadfiles/'
    bankIdRequest =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/bankid/client_request'
    bankIdCheck =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/bankid/client_check'
    markerPlace =
      'https://test-ocean-credit-app.herokuapp.com/api/affiliate/creditmarket/'
    catalogPopular =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/catalog/popular/'
    catalogCategoryBrands =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/allbrands/'
    desktopmessage =
      'https://test-ocean-credit-app.herokuapp.com/api/desktopmessage/'
    shoppingOrders =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/shopping-orders/'
    clientId = 'https://test-ocean-credit-app.herokuapp.com/api/customerident/'
    simpleRegistration =
      'https://test-ocean-credit-app.herokuapp.com/api/simple-registration/clients/'
    loginSimpleRegistration =
      'https://test-ocean-credit-app.herokuapp.com/api/simple-registration/clients/auth/'
    signAgreement =
      'https://test-ocean-credit-app.herokuapp.com/api/qes/contract/sign/'
    getSignedAgreement =
      'https://test-ocean-credit-app.herokuapp.com/api/qes/contract/'
    getWidgetMerchants =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/merchants/'
    phoneRegisterUpdate =
      'https://test-ocean-credit-app.herokuapp.com/api/phone-register-update/'
    formCount =
      'https://test-ocean-credit-app.herokuapp.com/api/clients/form-count/'
    widgetSession =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/widgetsession/'
    qrCodeMonkey =
      'https://test-ocean-credit-app.herokuapp.com/oceanapi/epos/qrcode/'
    sendSms = 'https://test-ocean-credit-app.herokuapp.com/api/widgetmessage/'
    ideaBankRequest =
      'https://test-ocean-credit-app.herokuapp.com/api/clients/get-ideabank-request/'
    posts = 'https://test-ocean-credit-app.herokuapp.com/api/blog/news/'
    services = 'https://test-ocean-credit-app.herokuapp.com/api/services/'
 
  }
  return {
    getToken: getToken,
    mainSiteURL: mainSiteURL,
    siteURL: siteURL,
    endPointClients: endPointClients,
    endPointRating: endPointRating,
    endPointFeedback: endPointFeedback,
    backendToken: backendToken,
    turnkeyClients: turnkeyClients,
    storeProducts: storeProducts,
    endPointHash: endPointHash,
    endPointServiceUrl: endPointServiceUrl,
    shopping: shopping,
    merchantRegister: merchantRegister,
    accountRequest: accountRequest,
    catalog: catalog,
    signForm: signForm,
    shoppingCategories: shoppingCategories,
    livnessDetection: livnessDetection,
    getMerchant: getMerchant,
    tkRequests: tkRequests,
    agentRegister: agentRegister,
    tkRequestsUpload: tkRequestsUpload,
    bankIdCheck: bankIdCheck,
    bankIdRequest: bankIdRequest,
    markerPlace: markerPlace,
    appsVisitLog: appsVisitLog,
    catalogPopular: catalogPopular,
    catalogCategoryBrands: catalogCategoryBrands,
    desktopmessage: desktopmessage,
    shoppingOrders: shoppingOrders,
    clientId: clientId,
    simpleRegistration: simpleRegistration,
    loginSimpleRegistration: loginSimpleRegistration,
    signAgreement: signAgreement,
    getSignedAgreement: getSignedAgreement,
    getWidgetMerchants: getWidgetMerchants,
    phoneRegisterUpdate: phoneRegisterUpdate,
    formCount: formCount,
    widgetSession: widgetSession,
    qrCodeMonkey: qrCodeMonkey,
    sendSms: sendSms,
    ideaBankRequest: ideaBankRequest,
    posts: posts,
    services: services,    
  }
})()
