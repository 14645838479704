import { makeApiCall } from '../../../src/utils/api/apiHelper.js'
import { ProdAndTestCred } from '../../../src/utils/api/main.js'

export default {
  actions: {
    async getPaymentCard(ctx) {
      const data = {
        method: 'get_cards_list',
        user_auth_token: localStorage.getItem('AuthToken')
      }

      await makeApiCall(data, ProdAndTestCred.tkRequests, ctx, 'updateCard')
    }
  },
  mutations: {
    updateCard(state, cards) {
      state.cards = cards.tk_response
      state.autoApprove = cards.autoApprove
    }
  },
  state: {
    cards: [],
    autoApprove: false
  },
  getters: {
    paymentCard(state) {
      return state.cards
    },
    autoApprove(state) {
      return state.autoApprove
    }
  }
}
