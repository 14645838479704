import { makeApiCall } from '../../../src/utils/api/apiHelper.js'
import { ProdAndTestCred } from '../../../src/utils/api/main.js'

export default {
  actions: {
    async getPersonalData(ctx) {
      const data = {
        method: 'get_customer_details',
        user_auth_token: localStorage.getItem('AuthToken')
      }

      await makeApiCall(data, ProdAndTestCred.tkRequests, ctx, 'updateDetails')
    }
  },
  mutations: {
    updateDetails(state, details) {
      state.details = details
    }
  },
  state: {
    details: []
  },
  getters: {
    customerDetails(state) {
      return state.details
    }
  }
}
