import { createStore } from 'vuex'
import customerDetails from "./modules/customerDetails";
import creditProducts from "./modules/creditProducts";
import activeLoans from "./modules/activeLoans";
import paymentCard from "./modules/paymentCard";
import phoneRegistrationData from "./modules/phoneRegistrationData";


export default new createStore({
  modules: {
    customerDetails,
    creditProducts,
    activeLoans,
    paymentCard,
    phoneRegistrationData,
  },
});
