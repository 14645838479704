import { configure, defineRule } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'
import { numeric, regex, digits } from '@vee-validate/rules'

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true
})

const INCOME_FIELD_REGEX = /^[0-9,]+$/
const CANT_BE_A_ZERO_REGEX = /^[1-9]/
const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const UKR_LETTERS_REGEX = /^[а-яієїґА-ЯІЄЇҐ]+$/
const DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

const PASSPORT_OLD = /^[A-Za-z]{2}\d{6}$/
const PASSPORT_NEW = /^\d{9}$/


const VALID_WEBSITE_REGEX = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
)

defineRule('phone', value => {
  if (value) {
    const phone = new PhoneNumber(value)
    if (phone.isValid()) {
      return true
    }
  }
  return `Приклад: +38 (066) 257-11-00`
})
defineRule('phone_additional', value => {
  if (value) {
    const phone = new PhoneNumber(value)
    if (phone.isValid()) {
      return true
    }
  }
  return `Введіть дійсний номер телефону`
})

defineRule('income', value =>
  INCOME_FIELD_REGEX.test(value) ? true : `Може містити тільки цифри`
)
defineRule('notZero', value =>
  CANT_BE_A_ZERO_REGEX.test(value) ? true : `Не може починатися з нуля`
)
defineRule('lastname', value =>
  UKR_LETTERS_REGEX.test(value) ? true : 'Введіть прізвище українською мовою'
)
defineRule('name', value =>
  UKR_LETTERS_REGEX.test(value) ? true : "Введіть ім'я українською мовою"
)
defineRule('middlename', value =>
  UKR_LETTERS_REGEX.test(value) ? true : "Введіть ім'я українською мовою"
)
defineRule('email', value =>
  VALID_EMAIL_REGEX.test(value)
    ? true
    : `Введіть дійсну електронну пошту. Приклад: myemail@gmail.com`
)
defineRule('passportNum', value => 
  (PASSPORT_OLD.test(value) || PASSPORT_NEW.test(value)) 
    ? true
    : `Введіть коректне значення.`
)
defineRule('newPassportNum', value =>
  value?.length >= 9 ? true : `Номер має містити 9 цифр`
)
defineRule('newPassportRegNum', value =>
  value?.length >= 14 ? true : `Приклад: 12345678-12345`
)
defineRule('oldPassportSerialNum', value =>
  value?.length >= 9 ? true : `Приклад: НЕ-123456`
)
defineRule('date', value =>
  DATE_REGEX.test(value) ? true : `Введіть дійсну дату. Приклад: 21.11.1990`
)
defineRule('datenum', value =>
  value.length < 10 ? `Введіть дійсну дату. Приклад: 21.11.1990` : true
)
defineRule('birthdate', value =>
  value &&
  new Date().getFullYear() - value?.split('.').join('').slice(4, 8) >= 18
    ? true
    : `Ваш вік має бути більше 18 років`
)
defineRule('iagree', value =>
  value === true ? true : `Щоб продовжити, Ви маєте погодитися з даною умовою`
)
defineRule('min', value =>
  value?.length >= 2 ? true : 'Занадто коротке значення'
)
defineRule('required', value =>
  ['', null, undefined].indexOf(value) === -1
    ? true
    : 'Це поле не може бути пустим'
)

defineRule('digits', () => ({
  ...digits,
  message: 'ІПН має містити 10 цифр'
}))
defineRule('regex', () => ({
  ...regex,
  message: 'Заповніть українською мовою'
}))
defineRule('numeric', () => ({
  ...numeric,
  message: 'Може містити тільки цифри'
}))
defineRule('issuedYear', value =>
  value > 1990 && value <= new Date().getFullYear() ? true : `Введіть дійсний рік. Наприклад: 2001`
)
defineRule('expYear', value =>
  value >= new Date().getFullYear()
    ? true
    : `Наприклад: ${new Date().getFullYear() + 1}`
)
defineRule('iban', value =>
  value.length >= 33 ? true : `Приклад: UA 21 322313 00000 26007233566001`
)
defineRule('zipcode', value =>
  value.length >= 5 ? true : `Індекс має містити 5 цифр`
)
defineRule('day', value => (value <= 31 ? true : 'Невірне значення'))
defineRule('website', value =>
  VALID_WEBSITE_REGEX.test(value)
    ? true
    : `Введіть дійсну адресу. Приклад: https://ocean.me`
)
