<template>
  <div id="app-child">
    <router-view />
  </div>
</template>

<script setup>
import axios from 'axios'
import { ProdAndTestCred } from '../src/utils/api/main.js'
import { getToken } from '../src/utils/api/getToken.js'
import { onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

function setUrlParams() {
  let objUrl = route.query
  let strUrl = ''
  for (let key in objUrl) {
    if (strUrl) {
      strUrl = `${strUrl}, ${key}: ${objUrl[key]}`
    } else {
      strUrl = `${key}: ${objUrl[key]}`
    }
  }
  localStorage.incomingParams = strUrl.slice(0, 250)
}

async function getFullFormValue() {
  try {
    const response = await axios.get(`${ProdAndTestCred.services}is-fullform/`)
    localStorage.fullform = response.data
  } catch {
    return
  }
}

const handleAuthenticatedUser = async () => {
  await Promise.all([
    store.dispatch('getActiveLoans'),
    store.dispatch('getPersonalData'),
    store.dispatch('getCreditProducts'),
    store.dispatch('getPaymentCard')
  ])
}

const handleUnauthenticatedUser = () => {
  if (!sessionStorage.recordDate) {
    incrementVisitCount()
    updatePreviousSites()
  }
}

const incrementVisitCount = () => {
  const currentCount = +localStorage.getItem('VisitCount') || 0
  localStorage.setItem('VisitCount', currentCount + 1)
}

const updatePreviousSites = () => {
  const referrer = document.referrer
  const existingSites = localStorage.getItem('PreviousSitesUrl') || ''
  const updatedSites = existingSites
    ? `${existingSites}, ${referrer}`
    : referrer
  localStorage.setItem('PreviousSitesUrl', updatedSites)
}

onBeforeMount(async () => {
  setUrlParams()

  localStorage.incomingAddress = location.href.slice(0, 250)

  if (navigator.userAgent.indexOf('gonative') > -1) {
    window.location.href =
      'gonative://statusbar/set?style=dark&color=ffffffff&overlay=false'
  }

  await Promise.all([getToken(), getFullFormValue()])

  const isAuth =
    localStorage.getItem('AuthToken') &&
    localStorage.getItem('AuthToken').length === 64

  isAuth ? await handleAuthenticatedUser() : handleUnauthenticatedUser()

  if (localStorage.tokenFastRegistration && localStorage.idFastRegistration) {
    await store.dispatch('getSimplePersonalData')
  }

  if (isAuth) {
    const keysArray = [
      'SpendTimeOnWebSite',
      'DateVisit',
      'VisitCount',
      'PreviousSitesUrl'
    ]

    keysArray.forEach(key => {
      localStorage.removeItem(key)
    })
  }
})
</script>

<style>
.v-toast {
  right: 10px !important;
}

select {
  -webkit-appearance: none;
  background-image: url('./assets/images/catalog/dropdown.svg');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7%;
}

.container-fluid {
  padding: 0 !important;
}

.card-header:first-child {
  background-color: #fafafa;
}

body {
  margin: 0;
  font-family: TT Hoves;
}

.nav-tabs .nav-link.active {
  border: none;
  color: black !important;
  font-weight: 500;
  cursor: pointer;
  background-color: #fafafa !important;
}

.nav-tabs .nav-link {
  color: rgba(0, 0, 0, 0.808);
  font-weight: 200;
  font-size: 16px;
  margin-top: 15px;
}

.nav-tabs .nav-link {
  color: rgba(0, 0, 0, 0.808);
  font-weight: 200;
  font-size: 18px;
  margin-top: 20px;
}

.nav-tabs .nav-link:hover {
  border: none;
  color: black;
  cursor: pointer;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.pagination-button.is-active {
  background: #000dff !important;
}

.splide__pagination li {
  margin: 3px !important;
}

@font-face {
  font-family: TT Hoves;
  src: url('./assets/fonts/TT Hoves Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'TT Hoves Medium';
  src: url('./assets/fonts/TT Hoves Medium.otf');
  font-display: swap;
}

@font-face {
  font-family: 'TT Hoves Bold';
  src: url('./assets/fonts/TT Hoves Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'TT Hoves DemiBold';
  src: url('./assets/fonts/TT Hoves DemiBold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF Pro.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'TT Hoves Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Agreement popup customization */

.swal2-content {
  text-align: justify !important;
}

.swal2-popup {
  width: 50rem !important;
}

/* FeedbackPage */
.vue-star-rating {
  margin: auto;
}

.vue-star-rating-rating-text {
  display: none;
}

/* reCAPTCHA badge */
.grecaptcha-badge {
  visibility: hidden;
}

/* Plotly */
.drag {
  pointer-events: none !important;
}

.yaxislayer-above,
.modebar {
  display: none;
}

.svg-container {
  z-index: -1;
  margin-top: -100px !important;
  margin-bottom: -55px !important;
  position: relative;
}

/* slider range at catalog */
.slider-thumb,
.slider-thumb-label,
.slider-track-fill {
  background-color: #000dff !important;
}

.slider-thumb {
  right: -14px !important;
}

/* Burger menu */
.line-style {
  background-color: transparent !important;
}

.bm-burger-button {
  height: 30px !important;
  left: 275px !important;
  position: absolute !important;
  top: -38px !important;
  min-width: 45px !important;
}

.line-style {
  height: 2px !important;
}

.bm-cross {
  height: 28px !important;
  background: #000dff !important;
}

.bm-menu {
  background-color: #f4f6f7f3 !important;
}

.bm-item-list {
  margin-left: 0 !important;
  margin-top: -37px !important;
}

.bm-item-list > * {
  padding: -0 35px !important;
  flex-direction: column;
}

.bm-item-list > span,
.bm-item-list > li {
  cursor: pointer;
  color: #fff !important;
}

.bm-item-list #partner,
.bm-item-list #non-partner {
  cursor: default !important;
}

.bm-item-list span.category {
  margin-left: 10px !important;
  font-size: 18px;
}

.bm-item-list > li > p {
  margin-bottom: -20px;
}

/* VueGallerySlideshow */
.VueCarousel-pagination {
  text-align: left !important;
}

.VueCarousel-dot-container {
  display: flex !important;
  align-items: center !important;
  margin-top: 5px !important;
}

.VueCarousel-dot {
  padding: 0 !important;
  margin-right: 12px !important;
  height: 8px !important;
  width: 8px !important;
  background-color: #ccc !important;
}

.VueCarousel-dot--active {
  height: 11px !important;
  width: 11px !important;
  background-color: #2e2e2ebd !important;
}

.vgs__container {
  height: 320px !important;
  background-color: #fff !important;
  top: 50px !important;
}

.vgs__next,
.vgs__prev {
  top: 200px !important;
  color: #1b1b1b !important;
}

.vgs__gallery {
  top: 380px !important;
}

@media (max-width: 767px) {
  .vgs__container {
    margin-top: 0 !important;
  }

  .vgs__gallery {
    display: block !important;
  }
}

/* splide slider */
.pagination-splide-1 {
  display: none !important;
  left: 54% !important;
  bottom: 1.5em !important;
  justify-content: flex-start !important;
}

/* VuePdfApp */
#viewFind {
  display: none !important;
}

.pdf-app .pdfViewer .page {
  margin-bottom: 10px !important;
}

@media (min-width: 1220px) {
  .bm-burger-button {
    display: none !important;
  }

  .pagination-splide-1 {
    left: 84% !important;
    bottom: 4.5em !important;
  }

  .splide__pagination__page {
    background: #dcdcdc !important;
  }
}

.progress .bg-primary {
  background-color: #fff !important;
}
</style>
