import axios from 'axios'
import { ProdAndTestCred } from './main'

export async function getToken() {
  const refresh = localStorage.getItem('refresh')
  if (refresh) {
    try {
      let response = await axios.post(
        `${ProdAndTestCred.getToken}refresh/`,
        { refresh },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      localStorage.setItem('access', 'Bearer ' + response.data.access)
      localStorage.setItem('refresh', response.data.refresh)
    } catch (err) {
      console.log()
      try {
        let response = await axios.post(
          `${ProdAndTestCred.getToken}`,
          { username: 'frontend', password: 'FrontendAdmin12345' },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        localStorage.setItem('access', 'Bearer ' + response.data.access)
        localStorage.setItem('refresh', response.data.refresh)
      } catch (err) {
        console.log(err)
      }
    }
  } else {
    try {
      let response = await axios.post(
        `${ProdAndTestCred.getToken}`,
        { username: 'frontend', password: 'FrontendAdmin12345' },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      localStorage.setItem('access', 'Bearer ' + response.data.access)
      localStorage.setItem('refresh', response.data.refresh)
    } catch {
      console.log()
    }
  }
}
