import { createRouter, createWebHistory } from 'vue-router'

// const MainPage = () => import('../components/Landings/MainPage.vue')
const Document = () => import('../components/PublicInfo/PublicInfo.vue')
const PrivacyPolicy = () => import('../components/PublicInfo/PrivacyPolicy.vue')
const ProtectedCategories = () =>
  import('../components/PublicInfo/ProtectedCategories.vue')
const ProtectionOfRight = () =>
  import('../components/PublicInfo/ProtectionOfRight.vue')
const About = () => import('../components/PublicInfo/About.vue')
const Regulations = () =>
  import('../components/PublicInfo/RegulationsComponent.vue')
const ContractsComponent = () =>
  import('../components/PublicInfo/ContractsComponent.vue')
const DelayInfo = () => import('../components/PublicInfo/DelayInfo.vue')
const AppealRegulator = () =>
  import('../components/PublicInfo/AppealRegulator.vue')
const ContactComponent = () =>
  import('../components/PublicInfo/ContactComponent.vue')
const Reports = () => import('../components/PublicInfo/ReportsComponent.vue')
const TermsAgreement = () =>
  import('../components/PublicInfo/TermsAgreement.vue')
const LoginPage = () => import('../components/Account/LoginPage.vue')
const AccountPage = () => import('../components/Account/AccountPage.vue')

const ResetPassword = () => import('../components/Account/ResetPassword.vue')
const AccountContact = () => import('../components/Account/AccountContact.vue')
const RegistrationPage = () =>
  import('../components/Landings/RegistrationPage.vue')
const Blog = () => import('../components/BlogComponent.vue')
const Post = () => import('../components/PostBlog.vue')
const AccountNewloan = () => import('../components/Account/AccountNewLoan')
const AccountFullForm = () => import('../components/Account/AccountFullForm')
const AccountCard = () => import('../components/Account/AccountCard')
const AccountPersonalInfo = () =>
  import('../components/Account/AccountPersonalInfo')
const Loan = () => import('../components/Account/LoanPage')
const ForgotPassword = () => import('../components/Account/ForgotPassword')
const PartnersPage = () => import('../components/Landings/PartnersPage')
const PayInfo = () => import('../components/PayInfo')
const RequestForm = () => import('../components/Verification/RequestForm.vue')
const ProductInfo = () => import('../components/Landings/ProductInfo')
const DetailsPage = () => import('../components/Landings/DetailsPage')
const PDFViewer = () => import('../components/Bricks/PDFViewer')
const DebitPage = () => import('../components/DebitPage')

const routes = [
  // {
  //   path: '/',
  //   name: 'MainPage',
  //   component: MainPage,
  //   },
      {
    path: '/',
    name: 'PayInfo',
    component: PayInfo
  },
  // {
  //   path: '/',
  //   name: 'MainPage',
  //   component: MainPage,
  //   },
  { path: '/pdfview/:id', name: 'PDFViewer', component: PDFViewer },
  {
    path: '/details',
    name: 'DetailsPage',
    component: DetailsPage
  },
  {
    path: '/documents',
    name: 'Document',
    component: Document
  },
  {
    path: '/document/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/document/protected-categories',
    name: 'ProtectedCategories',
    component: ProtectedCategories
  },
  {
    path: '/document/about',
    name: 'About',
    component: About
  },
  {
    path: '/document/delay-info',
    name: 'DelayInfo',
    component: DelayInfo
  },
  {
    path: '/document/appeal-regulator',
    name: 'AppealRegulator',
    component: AppealRegulator
  },
  {
    path: '/document/contact',
    name: 'Contact',
    component: ContactComponent
  },
  {
    path: '/document/regulations',
    name: 'Regulations',
    component: Regulations
  },
  {
    path: '/document/contracts',
    name: 'Contracts',
    component: ContractsComponent
  },
  {
    path: '/document/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/document/terms-agreement',
    name: 'TermsAgreement',
    component: TermsAgreement
  },
  {
    path: '/document/protection-of-right',
    name: 'ProtectionOfRight',
    component: ProtectionOfRight
  },
  {
    path: '/partners',
    name: 'PartnersPage',
    component: PartnersPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/login/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage
  },
  {
    path: '/account/card',
    name: 'AccountCard',
    component: AccountCard
  },
  {
    path: '/account/newloan',
    name: 'AccountNewloan',
    component: AccountNewloan
  },
  {
    path: '/account/fullform',
    name: 'AccountFullForm',
    component: AccountFullForm
  },
  {
    path: '/account/personalinfo',
    name: 'AccountPersonalInfo',
    component: AccountPersonalInfo
  },
  {
    path: '/account/activeloans/:name',
    name: 'Loan',
    component: Loan
  },
  {
    path: '/product/:id',
    name: 'ProductInfo',
    component: ProductInfo
  },
  {
    path: '/account/contact',
    name: 'AccountContact',
    component: AccountContact
  },
  {
    path: '/registration',
    name: 'Registration',
    component: RegistrationPage
  },

  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/:name',
    name: 'Post',
    component: Post
  },


  {
    path: '/fullform',
    name: 'RequestForm',
    component: RequestForm
  },
  {
    path: '/debit',
    name: 'DebitPage',
    component: DebitPage
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    setTimeout(() => window.scrollTo(0, 0), 0)
  }
})

export default router
